//lib
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Field, FieldArray, useFormikContext } from "formik";

export default function Checkbox({
  type,
  label,
  labelValue,
  labelTrue,
  labelFalse,
  name,
  options,
  className,
  classNameCheckbox,
  onChange,
  checked,
}) {
  const { values, errors } = useFormikContext();
  return (
    <MDBCol className={`element _checkbox ${className ? className : ""}`}>
      {label && (
        <MDBRow>
          <label className="form-label">{label}</label>
        </MDBRow>
      )}
      {type === "array" ? (
        <FieldArray
          className={className}
          name={name}
          render={(arrayHelpers) => (
            <MDBRow className="align-items-center">
              {options.map((listing, index) => (
                <MDBCol
                  key={index}
                  className={`col-12 checkbox-button ${className}`}
                >
                  <div className="checkbox-wrapper">
                    <label key={listing.id}>
                      <Field
                        name={name}
                        type="checkbox"
                        value={listing.value}
                        checked={
                          name ? values[name].includes(listing.value) : ""
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(listing.value);
                          } else {
                            const idx = values[name].indexOf(listing.value);
                            arrayHelpers.remove(idx);
                          }
                        }}
                      />
                      <span className="checkbox"></span>
                      <span>&nbsp;&nbsp;{listing.label}</span>
                    </label>
                  </div>
                </MDBCol>
              ))}
            </MDBRow>
          )}
        />
      ) : (
        <label
          style={{ gap: "1em" }}
          className={`checkbox-button d-flex align-items-center pointer ${classNameCheckbox}`}
        >
          <div className="checkbox-wrapper">
            <label>
              <input
                className="pointer"
                type="checkbox"
                name={name}
                onChange={onChange ? onChange : undefined}
                checked={checked}
              />
              <span className="checkbox"></span>
            </label>
          </div>

          {labelValue && (
            <span className="mt-1">
              {labelValue
                ? labelValue
                : values[name] === true && labelTrue
                ? labelTrue
                : values[name] === false && labelFalse
                ? labelFalse
                : values[name].toString()}
            </span>
          )}
        </label>
      )}
      {/* {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message">
          {errors[name]}
        </div>
      ) : null} */}
    </MDBCol>
  );
}
