import { useDispatch, useSelector } from "react-redux";
import { closeGallery } from "../../redux/reducer/modalReducer";
import { useToast } from "./common.toast";
import { useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import { Lightbox as Videobox } from "yet-another-react-lightbox";
import { ComingSoon } from "../../assets/images";
import { CommonVertical } from "../../assets/videos";
import Video from "yet-another-react-lightbox/plugins/video";

export default function ImageGallery({ type }) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { imageGallery } = useSelector((state) => state.common);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCloseGallery = () => {
    dispatch(closeGallery());
  };

  // const handleDownload = async (url) => {
  //   try {
  //     const response = await fetch(url);
  //     if (!response.ok) {
  //       toast.error(`Download media failed, please try again later.`);
  //     }

  //     const blob = await response.blob();
  //     const objectUrl = URL.createObjectURL(blob);

  //     const anchor = document.createElement("a");
  //     anchor.href = objectUrl;

  //     const fileName = url.split("/").pop() || "download";
  //     anchor.download = fileName;

  //     document.body.appendChild(anchor);
  //     anchor.click();

  //     URL.revokeObjectURL(objectUrl);
  //     document.body.removeChild(anchor);

  //     toast.success(`Download media successfully.`);
  //   } catch (error) {
  //     toast.error(`Download media failed: ${error}`);
  //   }
  // };

  // const rotateRight = (imageIndex) => {
  //   const img = document.getElementById(`file-preview-${imageIndex}`);
  //   const currentRotation = img.style.transform.match(/rotate\((\d+)deg\)/);
  //   const newRotation = currentRotation
  //     ? parseInt(currentRotation[1]) + 90
  //     : 90;
  //   img.style.transform = `rotate(${newRotation}deg)`;
  // };

  // const rotateLeft = (imageIndex) => {
  //   const img = document.getElementById(`file-preview-${imageIndex}`);
  //   const transform = img.style.transform;
  //   const match = transform.match(/rotate\(([-\d]+)deg\)/);
  //   const currentRotation = match ? parseInt(match[1]) : 0;
  //   const newRotation = currentRotation - 90;

  //   img.style.transform =
  //     transform.replace(/rotate\(([-\d]+)deg\)/, `rotate(${newRotation}deg)`) ||
  //     `rotate(${newRotation}deg)`;
  // };

  // const zoomIn = (imageIndex) => {
  //   const img = document.getElementById(`file-preview-${imageIndex}`);
  //   const currentScale = img.style.transform.match(/scale\((\d+(\.\d+)?)\)/);
  //   const newScale = currentScale ? parseFloat(currentScale[1]) + 0.1 : 1.1;
  //   img.style.transform = `scale(${newScale})`;
  // };

  // const zoomOut = (imageIndex) => {
  //   const img = document.getElementById(`file-preview-${imageIndex}`);
  //   const currentScale = img.style.transform.match(/scale\((\d+(\.\d+)?)\)/);
  //   const newScale = currentScale ? parseFloat(currentScale[1]) - 0.1 : 0.9;
  //   img.style.transform = `scale(${newScale})`;
  // };

  // useEffect(() => {
  //   if (imageGallery?.length > 0) {
  //     setCurrentIndex(imageGallery[0]?.index);
  //   }
  // }, []);

  // const initializeZoom = (imageIndex) => {
  //   const element = document.getElementById(`file-preview-${imageIndex}`);
  //   let scale = 1;
  //   let lastScale = 1;

  //   const hammer = new Hammer(element);
  //   hammer.get("pinch").set({ enable: true });

  //   hammer.on("pinch", (event) => {
  //     scale = lastScale * event.scale;
  //     element.style.transform = `scale(${scale})`;
  //   });

  //   hammer.on("pinchend", () => {
  //     lastScale = scale;
  //   });

  //   element.addEventListener("wheel", (e) => {
  //     e.preventDefault();
  //     const delta = e.deltaY > 0 ? -0.1 : 0.1;
  //     scale = Math.min(Math.max(1, scale + delta), 5);
  //     element.style.transform = `scale(${scale})`;
  //   });
  // };

  // useEffect(() => {
  //   if (Array.isArray(imageGallery)) {
  //     imageGallery.forEach((_, index) => {
  //       initializeZoom(index);
  //     });
  //   }
  // }, [imageGallery]);

  return (
    <div>
      {type === "video" ? (
        <Videobox
          open={true}
          close={() => handleCloseGallery()}
          plugins={[Video]}
          slides={imageGallery.map((video) => ({
            type: "video",
            width: 1280,
            height: 720,
            sources: video.sources, // Using dynamic sources
          }))}
          video={{
            control: true,
            playsInline: true,
            autoPlay: true,
            loop: true,
            muted: true,
          }}
        />
      ) : (
        <Lightbox
          mainSrc={imageGallery[currentIndex]?.url}
          onCloseRequest={() => handleCloseGallery()}
          onMovePrevRequest={() =>
            setCurrentIndex(
              (currentIndex + imageGallery.length - 1) % imageGallery.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % imageGallery.length)
          }
          {...(imageGallery?.length > 1
            ? {
                nextSrc: imageGallery[(currentIndex + 1) % imageGallery.length],
                prevSrc:
                  imageGallery[
                    (currentIndex + imageGallery.length - 1) %
                      imageGallery.length
                  ],
              }
            : {})}
        />
      )}
    </div>
  );
}
