import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  isOpenLoginModal: false,
  isOpenMenuTab: false,
  isOpenAccessoriesModal: false,
  isOpenPromotionModal: null,
  isOpenEditPriceModal: false,
  isOpenFilterModal: false,
  isOpenConfirmModal: false,
  isOpenConfirmModal: false,

  isOpenGallery: false,
};

const dataSlice = createSlice({
  name: "modal",
  initialState: initialData,
  reducers: {
    openLoginModal: (state, action) => {
      state.isOpenLoginModal = true;
    },
    openAccessoriesModal: (state, action) => {
      state.isOpenAccessoriesModal = true;
    },
    openPromotionModal: (state, action) => {
      state.isOpenPromotionModal = action.payload;
    },
    openEditPriceModal: (state, action) => {
      state.isOpenEditPriceModal = true;
    },
    openFilterModal: (state, action) => {
      state.isOpenFilterModal = true;
    },
    openConfirmModal: (state, action) => {
      state.isOpenConfirmModal = true;
    },
    openMenuTab: (state, action) => {
      state.isOpenMenuTab = true;
    },
    openGallery: (state, action) => {
      state.isOpenGallery = true;
    },

    closeLoginModal: (state, action) => {
      state.isOpenLoginModal = false;
    },
    closeAccessoriesModal: (state, action) => {
      state.isOpenAccessoriesModal = false;
    },
    closePromotionModal: (state, action) => {
      state.isOpenPromotionModal = null;
    },
    closeEditPriceModal: (state, action) => {
      state.isOpenEditPriceModal = false;
    },
    closeFilterModal: (state, action) => {
      state.isOpenFilterModal = false;
    },
    closeConfirmModal: (state, action) => {
      state.isOpenConfirmModal = false;
    },
    closeMenuTab: (state, action) => {
      state.isOpenMenuTab = false;
    },
    closeGallery: (state, action) => {
      state.isOpenGallery = false;
    },
    closeAllModal: (state, action) => {
      state.isOpenLoginModal = false;
      state.isOpenMenuTab = false;
      state.isOpenAccessoriesModal = false;
      state.isOpenPromotionModal = null;
      state.isOpenEditPriceModal = false;
      state.isOpenFilterModal = false;
      state.isOpenConfirmModal = false;
      state.isOpenGallery = false;
    },
    // used for modalbox animation
    setAnimationModal: (state, action) => {
      state.isAnimationModal = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  openMenuTab,
  openLoginModal,
  openAccessoriesModal,
  openPromotionModal,
  openEditPriceModal,
  openFilterModal,
  openConfirmModal,
  openGallery,

  closeMenuTab,
  closeLoginModal,
  closeAccessoriesModal,
  closePromotionModal,
  closeEditPriceModal,
  closeFilterModal,
  closeConfirmModal,
  closeGallery,

  closeAllModal,
  setAnimationModal,
  setShowModal,
} = dataSlice.actions;

export default dataSlice.reducer;
