import { useDispatch, useSelector } from "react-redux";
import { MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Icon } from "@iconify/react";
import {
  closePromotionModal,
  openGallery,
} from "../../../redux/reducer/modalReducer";
import { ComingSoon, DefaultJustint } from "../../../assets/images";
import FullModal from "../../../components/modal/full.modal.box";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import ModalHeader from "../../../components/header/modal.header";
import { useToast } from "../../../components/common/common.toast";
import Input from "../../../components/element/input";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  sendPromotion,
  setSendPromotionLoading,
} from "../../../redux/reducer/promotionReducer";
import { setImageGallery } from "../../../redux/reducer/commonReducer";

export default function PromotionModal() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { promotionList, sendPromotionLoading } = useSelector(
    (state) => state.promotion
  );
  const { isOpenPromotionModal } = useSelector((state) => state.modal);

  const handleCloseModal = () => {
    dispatch(closePromotionModal());
  };

  const handleOpenGallery = (media) => {
    dispatch(openGallery());

    const gallery =
      media?.length > 0
        ? media.map((media) => {
            return {
              url: media.url,
              api_url: media.api_url,
              type: "image",
            };
          })
        : [];

    dispatch(setImageGallery(gallery));
  };

  const promotionForm = {
    voucher_id: isOpenPromotionModal,
    phone_number: "",
  };

  const promotionSchema = Yup.object().shape({
    phone_number: Yup.string()
      .matches(/^(11\d{7,8}|1[0-9]\d{7})$/, "Please enter a valid phone number")
      .nullable(),
  });

  const promotionInfo = promotionList.find(
    (promotion) => promotion.id === isOpenPromotionModal
  );

  const handleSubmit = async (values) => {
    dispatch(setSendPromotionLoading(true));

    const formData = new FormData();

    const attachment =
      promotionInfo.media?.length > 0 ? promotionInfo.media[0].api_url : null;

    if (attachment) {
      try {
        const response = await fetch(attachment);
        const blob = await response.blob();
        const file = new File([blob], "attachment.jpg", { type: blob.type });

        formData.append("attachments[0]", file);
      } catch (error) {
        console.error(
          "Error converting promotion image to binary file:",
          error
        );
      }
    }

    formData.append("voucher_id", values.voucher_id);
    formData.append("phone_number", `+60${values.phone_number}`);

    dispatch(sendPromotion({ data: formData }))
      .unwrap()
      .then((res) => {
        handleCloseModal();
        toast.success(
          `Send promotion to customer +60${values.phone_number} successfully.`
        );
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0]);
            });
          }
        }
      });
  };

  return (
    <FullModal
      staticBackdrop={true}
      show={isOpenPromotionModal !== null}
      backButton={handleCloseModal}
      screenSize={width >= 770 ? "xl" : "fullscreen-xl-down"}
      className="overflow-hidden"
      dialogClassName="width-500"
      contentClassName="br-34"
      content={
        <>
          <ModalHeader
            className="br-34 fixed"
            backTo={handleCloseModal}
            title="Promotion Details"
          />
          <Formik
            initialValues={promotionForm}
            validationSchema={promotionSchema}
            onSubmit={(values, { setFieldError }) => {
              handleSubmit(values, setFieldError);
            }}
          >
            {({ values, isValid, setFieldValue }) => (
              <Form className="formik-form h-100">
                <MDBModalBody>
                  <article className="promotion-modal">
                    <section
                      className="promotion-frame"
                      onClick={() => {
                        handleOpenGallery(promotionInfo.media);
                      }}
                    >
                      <LazyLoadImage
                        src={
                          promotionInfo.media?.length > 0
                            ? promotionInfo.media[0].url
                            : DefaultJustint
                        }
                      />
                      <article
                        className="zoom-in"
                        onClick={() => {
                          handleOpenGallery(promotionInfo.media);
                        }}
                      >
                        <Icon icon="ri:zoom-in-line" />
                      </article>
                    </section>
                    <section className="promotion-info">
                      <h6>{promotionInfo.name}</h6>
                      <span>
                        Valid from {promotionInfo.start_at} until{" "}
                        {promotionInfo.end_at}
                      </span>
                      <p
                        className="desc"
                        dangerouslySetInnerHTML={{
                          __html: promotionInfo.description,
                        }}
                      />
                    </section>
                  </article>
                </MDBModalBody>
                <MDBModalFooter
                  className={`${width < 500 ? "mobile" : ""}`}
                  style={{
                    position: width < 500 ? "fixed" : "sticky",
                  }}
                >
                  <section className="footer-promotion">
                    <article className="contact-input">
                      <p>+60</p>
                      <input
                        name="phone_number"
                        placeholder="123456789"
                        onChange={(e) =>
                          setFieldValue("phone_number", e.target.value)
                        }
                      />
                      <button
                        disabled={
                          !values.phone_number ||
                          !isValid ||
                          sendPromotionLoading
                        }
                        type="submit"
                      >
                        <h4>Send</h4>
                      </button>
                    </article>
                  </section>
                </MDBModalFooter>
              </Form>
            )}
          </Formik>
        </>
      }
    />
  );
}
