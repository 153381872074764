import { LazyLoadImage } from "react-lazy-load-image-component";
import { ComingSoon, DefaultJustint } from "../../../assets/images";
import Button from "../../../components/element/button";
import { useDispatch } from "react-redux";
import { openPromotionModal } from "../../../redux/reducer/modalReducer";
import dayjs from "dayjs";

export default function PromotionCard({ promotion }) {
  const dispatch = useDispatch();

  return (
    <section
      className="promotion-item"
      onClick={() => dispatch(openPromotionModal(promotion.id))}
    >
      <article className="promotion-card">
        <section className="promotion-frame">
          <LazyLoadImage
            src={
              promotion.media?.length > 0
                ? promotion.media[0].url
                : DefaultJustint
            }
          />
        </section>
        <section className="promotion-frame">
          <p>{promotion.name}</p>
          <hr />
          <article className="promotion-action">
            {/* <article>
              <h4>RM 50.00</h4>
            </article> */}
            <Button className="w-100 flex justify-center">
              Send Promotion
            </Button>
            <span>
              Valid till {dayjs(promotion.end_at).format("MMMM D, YYYY")}
            </span>
          </article>
        </section>
      </article>
    </section>
  );
}
