import { useSelector } from "react-redux";
import ChooseBodyType from "../../components/common/choose.body.type";
import ImageWithText from "../../components/common/image.with.text";
import ChooseSeries from "./components/choose.series";
import TintedPackage from "./components/tinted.package";
import { useEffect } from "react";
import scrollToTop from "../../helpers/scroll.to.top";
import ImageGallery from "../../components/common/image.gallery";
import CommonVideo from "../../components/common/common.video";
import CommonPromotion from "../../components/common/common.promotion";

export default function CarTinted() {
  const { fourBodyTypeList } = useSelector((state) => state.common);
  const { isOpenGallery } = useSelector((state) => state.modal);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <article
      className="car-tinted-page w-100 flex flex-column items-center"
      style={{ gap: "50px" }}
    >
      {/* <CommonPromotion /> */}
      <ImageWithText locateAt="car-tinted" />
      <ChooseSeries />
      <ChooseBodyType bodyTypeList={fourBodyTypeList} className="tinted" />
      <TintedPackage />
      <CommonVideo locateAt="car-tinted" />
      {isOpenGallery && <ImageGallery type="video" />}
    </article>
  );
}
