import { useSelector } from "react-redux";
import Input from "../../../components/element/input";
import { Icon } from "@iconify/react";
import { BodyHeader, TotalPriceRow } from "./cart.body";
import Dropdown from "../../../components/element/dropdown";
import Select from "../../../components/element/select";

export default function CustomerDetail() {
  const { modelOptions } = useSelector((state) => state.common);
  const { cartInfo, cartLoading, createOrderLoading } = useSelector(
    (state) => state.cart_order
  );

  const customerFormList = [
    {
      label: "Name",
      placeholder: "eg. John Doe",
      name: "name",
      is_required: true,
    },
    {
      label: "Car Model",
      placeholder: "Select Car Model",
      name: "car_model",
      component: "dropdown",
    },
    {
      label: "Car Chassis No.",
      placeholder: "",
      name: "car_chassis_number",
    },
    {
      label: "Contact No.",
      name: "contact",
      type: "number",
      placeholder: "123456789",
      input_classname: "phone_no",
      is_required: true,
    },
    {
      label: "Car Plate No.",
      name: "car_no_plate",
      placeholder: "eg VVV 123",
    },
    {
      label: "Remark",
      name: "note",
    },
  ];

  return (
    <section className="form-section" id="customer-details">
      <article className="customer-detail">
        <BodyHeader icon="nimbus:store" title="Customer Details" />
        <section className="form-list">
          {customerFormList.map((form, formIndex) => (
            <article className="form-item" key={formIndex}>
              {form.component === "dropdown" ? (
                <Select
                  name={form.name}
                  label={form.label}
                  placeholder={form.placeholder}
                  options={modelOptions}
                  formatGroupLabel={(group) => <strong>{group.label}</strong>}
                />
              ) : (
                <Input
                  as="round-field"
                  type={form.type}
                  inputClassName={form.input_classname}
                  name={form.name}
                  label={form.label}
                  placeholder={form.placeholder}
                  isRequired={form.is_required}
                  errorClassName="mt-2 mb-2"
                />
              )}
            </article>
          ))}
          <article className="fyi">
            <article>
              <Icon icon="eva:info-outline" />
            </article>
            <p className="text-start">
              In order for the spare parts to match your car and to avoid the
              error, enter the chassis number It consists of 17 numbers and
              letters in the license
            </p>
          </article>
        </section>
        <TotalPriceRow
          cartInfo={cartInfo}
          loading={cartLoading || createOrderLoading}
          buttonTitle="Place Order"
        />
      </article>
    </section>
  );
}
