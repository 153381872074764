import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { Icon } from "@iconify/react";
import AccessoriesFilter from "./accessories.filter";
import {
  getBrandList,
  getModelList,
} from "../../../redux/reducer/commonReducer";
import { getCategoryList } from "../../../redux/reducer/commonReducer";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import {
  getProductList,
  setFilterCategory,
  setFilterPage,
  setFilterSearch,
} from "../../../redux/reducer/serviceReducer";

export default function AccessoriesHeader() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [showMoreCategory, setShowMoreCategory] = useState(false);
  const initialCategoryItem = width < 600 ? 6 : 11;

  const {
    categoryList,
    categoryLoading,
    filterBrand,
    filterModel,
    filterYear,
  } = useSelector((state) => state.common);
  const { filterCategory, filterSearch, filterPage } = useSelector(
    (state) => state.service
  );

  const currentYear = new Date().getFullYear();

  const generateYearRange = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleShowMoreCategory = () => {
    setShowMoreCategory(!showMoreCategory);
  };

  const handleSelectCategory = (category) => {
    let categoryList = [];

    if (category === "all") {
      dispatch(setFilterCategory([]));
    } else {
      if (filterCategory.length > 0 && filterCategory.includes(category)) {
        dispatch(
          setFilterCategory(filterCategory.filter((item) => item !== category))
        );

        categoryList = filterCategory.filter((item) => item !== category);
      } else {
        dispatch(setFilterCategory([...filterCategory, category]));

        categoryList = [...filterCategory, category];
      }
    }

    getFilterProductList(null, categoryList);
  };

  const getFilterProductList = (clear, categoryList) => {
    dispatch(setFilterPage(0));
    dispatch(
      getProductList({
        start: 0,
        length: 12,
        service: "accessory",
        search: clear === "clear" ? "" : filterSearch,
        category_id: categoryList ? categoryList : filterCategory,
        brand_id: filterBrand.map((brand) => brand.id),
        model_id: filterModel.map((model) => model.id),
        model_year:
          filterYear[0] !== 1990 || filterYear[1] !== currentYear
            ? generateYearRange(filterYear[0], filterYear[1])
            : [],
      })
    );
  };

  useEffect(() => {
    dispatch(setFilterSearch(""));
    dispatch(getBrandList());
    dispatch(getModelList());
    dispatch(getCategoryList());
  }, []);

  return (
    <section className="accessories-header">
      <article className="w-100">
        <h3>Latest Products</h3>
        <p>Jus-Tint are link with strong big company in the malaysia</p>
        <section className="accessories-search mt-5">
          <AccessoriesFilter />
          <input
            type="text"
            value={filterSearch}
            placeholder="Search by item name"
            onChange={(e) => dispatch(setFilterSearch(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getFilterProductList();
              }
            }}
          />
          <article className="flex items-center gap-3">
            <section
              className="clear-icon pointer"
              onClick={() => {
                dispatch(setFilterSearch(""));
                getFilterProductList("clear", filterCategory);
              }}
            >
              <Icon icon="iconamoon:close-duotone" />
            </section>
            <Button
              btnClassName="common-button search"
              onClick={() => getFilterProductList()}
            >
              <Icon icon="majesticons:search-line" className="--search" />
              <p>Search</p>
            </Button>
          </article>
        </section>
        <hr />
        <section className="accessories-category">
          <article
            className={`category-item ${
              filterCategory.length === 0 ? "--active" : ""
            } pointer`}
            onClick={() => handleSelectCategory("all")}
          >
            <p>All</p>
          </article>
          {!categoryLoading
            ? (showMoreCategory
                ? [...categoryList]
                : [...categoryList.slice(0, initialCategoryItem)]
              )
                .sort((a, b) => {
                  const isANumeric = /^\d/.test(a.name);
                  const isBNumeric = /^\d/.test(b.name);

                  if (isANumeric && !isBNumeric) return 1;
                  if (!isANumeric && isBNumeric) return -1;
                  return a.name.localeCompare(b.name, undefined, {
                    numeric: true,
                  });
                })
                .map((category, categoryIndex) => (
                  <article
                    key={categoryIndex}
                    className={`category-item ${
                      filterCategory.length > 0 &&
                      filterCategory.includes(category.id)
                        ? "--active"
                        : ""
                    } pointer`}
                    onClick={() => handleSelectCategory(category.id)}
                  >
                    <p>{category.name}</p>
                  </article>
                ))
            : Array.from(
                { length: width < 991 ? 4 : width < 550 ? 2 : 5 },
                (_, categorySkeletonIndex) => (
                  <Skeleton
                    width="120px"
                    height="38px"
                    className="category-item"
                    key={categorySkeletonIndex}
                  />
                )
              )}
          {categoryList.length > initialCategoryItem && (
            <article
              onClick={handleShowMoreCategory}
              className="show-category pointer"
            >
              <p>
                {showMoreCategory
                  ? "Show Less"
                  : `+ ${categoryList?.length - initialCategoryItem} More`}
              </p>
            </article>
          )}
        </section>
      </article>
    </section>
  );
}
