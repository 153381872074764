import { Icon } from "@iconify/react";
import Button from "../../../components/element/button";
import { useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import PromotionCard from "./promotion.card";
import { useDispatch, useSelector } from "react-redux";
import { getPromotionList } from "../../../redux/reducer/promotionReducer";
import Lottie from "lottie-react";
import { Empty, Loading } from "../../../assets/lotties";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

const { RangePicker } = DatePicker;

export default function PromotionList() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [searchDate, setSearchDate] = useState([]);
  const [searchDisplay, setSearchDisplay] = useState("");
  const [search, setSearch] = useState("");

  const { promotionLoading, promotionList } = useSelector(
    (state) => state.promotion
  );

  useEffect(() => {
    dispatch(
      getPromotionList({
        search: search || "",
      })
    );
  }, [search]);

  return (
    <section className="promotion-list">
      <article
        className={`flex flex-wrap justify-between items-center ${
          width > 550 ? "pe-4" : "gap-4 pb-3"
        }`}
      >
        <h5>Promotions</h5>
        <section className={`relative ${width < 550 ? "w-100" : ""}`}>
          {/* ${width < 820 ? "w-100 ps-4 pe-4" : ""} */}
          <article className="search-bar">
            {/* <RangePicker
              value={
                searchDate?.length > 0
                  ? [dayjs(searchDate[0]), dayjs(searchDate[1])]
                  : []
              }
              //   onChange={(date, dateString) => {
              //     handleDateChange(date, dateString);
              //   }}
              //   popupClassName={width < 550 ? "dateRangePicker" : undefined}
            /> */}
            {/* <section className="search-divider" /> */}
            <section className="flex w-100">
              <input
                value={searchDisplay}
                placeholder="Search something.."
                onChange={(e) => setSearchDisplay(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch(searchDisplay);
                  }
                }}
              />
              <Button onClick={() => setSearch(searchDisplay)}>
                <Icon icon="majesticons:search-line" />
                <p>Search</p>
              </Button>
            </section>
          </article>
        </section>
      </article>
      <hr />
      {!promotionLoading ? (
        promotionList?.length > 0 ? (
          <article className="list-wrapper">
            {promotionList.map((promotion, promotionIndex) => (
              <article key={promotionIndex}>
                <PromotionCard promotion={promotion} />
              </article>
            ))}
          </article>
        ) : (
          <article className="w-100 minh-480 flex flex-column items-center">
            <article className="common-lottie --empty">
              <Lottie loop={2} animationData={Empty} />
            </article>
            <h6 className="--loading text-center">No data found</h6>
          </article>
        )
      ) : (
        <article className="w-100 minh-480 flex flex-column items-center text-center">
          <article className="common-lottie">
            <Lottie animationData={Loading} />
          </article>
          <h6 className="--loading text-center">Loading ..</h6>
        </article>
      )}
    </section>
  );
}
