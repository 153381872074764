export default function CommonText({ locateAt }) {
  const commonText = {
    "about-us": {
      h2: "Introducing Our Dedicated Team",
      h6: "Meet the Experts",
      p: `With over 20 years of collective industry expertise, our team has been the driving force behind the company's growth and success. <br /><br />
          Guided by strong leadership, we are committed to delivering exceptional service. 
          Passionate about excellence, our team ensures every customer receives top-quality care along with valuable professional advice and insights.`,
    },
    "why-justint": {
      h2: "Why Choose <br /> Justint",
      h6: "YOUR TRUSTED PARTNER",
      p: `We combine expertise, premium materials, and personalized service to bring out the best in your vehicle. From expert tinting to durable PPF and custom wrapping, our team ensures top-tier protection and unmatched style. With a focus on quality and customer satisfaction, we tailor every service to your needs, guaranteeing a result you’ll love and trust for the long haul.`,
    },
    home: {
      h2: "Expert Vehicle Protection",
      h6: "Passionate About Perfection",
      p: "With years of experience, we deliver precision and expertise in every project. Our certified technicians are committed to ensuring exceptional results, providing high-quality care for your vehicle every time.",
    },
    "car-tinted": {
      h2: "High-Quality Vehicle Window Tinting",
      h6: "Enhance Comfort & Privacy",
      p: `Our window tinting service offers more than just style. We use top-grade films to reduce heat, block harmful UV rays, and improve privacy inside your vehicle. Whether you're looking for a sleek, dark finish or a subtle, protective layer, our expert tinting solutions enhance comfort and aesthetics while ensuring long-lasting durability.`,
    },
    "car-tinted-top": {
      with_border: true,
      h2: "Watch Our Works",
      h6: "Enhance Comfort & Privacy",
      // p: `Our window tinting service offers more than just style. We use top-grade films to reduce heat, block harmful UV rays, and improve privacy inside your vehicle. Whether you're looking for a sleek, dark finish or a subtle, protective layer, our expert tinting solutions enhance comfort and aesthetics while ensuring long-lasting durability.`,
    },
    "car-ppf": {
      with_border: true,
      h2: "Durable Paint Protection Film",
      h6: "Invisible Protection, Lasting Durability",
      p: `Protect your vehicle's paint from scratches, chips, and environmental damage with our high-performance Paint Protection Film (PPF). This invisible, self-healing film preserves your car’s finish without altering its appearance. Ideal for maintaining that showroom shine, PPF provides long-lasting protection, ensuring your car stays looking brand new for years to come.`,
    },
    "car-coating-top": {
      h2: "Award-Winning Ceramic Coating Excellence",
      h6: "Passionate About Perfection",
      p: `Our ceramic coating services have been recognized for their superior quality and outstanding performance. We take pride in using industry-leading products and techniques that have earned us multiple awards and certifications for excellence in vehicle protection. These accolades reflect our commitment to delivering top-tier results, ensuring your car not only looks stunning but is protected for the long term. Trust the expertise that's been recognized by professionals and customers alike.`,
    },
    "car-coating-bottom": {
      h2: "Ceramic Coating for Ultimate Protection",
      h6: "Long-Lasting Shine & Protection",
      p: `Give your car the ultimate protection and shine with our ceramic coating services. This advanced coating forms a durable, glossy layer that shields your vehicle from dirt, water, and UV damage. With its hydrophobic properties, ceramic coating makes cleaning easier and keeps your car looking polished and new for longer periods. It's the perfect solution for long-term vehicle care.`,
    },
  };

  return (
    <article
      className={`common-text ${
        commonText[locateAt]?.with_border ? "--border" : ""
      }${locateAt === "why-justint" ? "text-black pb-0" : ""}`}
    >
      <article className="flex flex-column gap-2">
        {/* <p><span />Comprehensive Car Care</p>
                <article className="one-stop-text">
                    <h2>Your One-Stop Car Care Solution</h2>
                    <p className="--subtitle">We offers a wide range of services to enhance and protect your vehicle. Our experienced team is dedicated to ensuring your car looks its best and remains in excellent condition, providing customized solutions for all your automotive care needs.</p>
                </article> */}
        <h6>
          {commonText[locateAt]?.with_border && <span />}
          {commonText[locateAt]?.h6}
        </h6>
        <section className="with-border">
          {!commonText[locateAt]?.with_border && <article className="border" />}
          <h2
            dangerouslySetInnerHTML={{ __html: commonText[locateAt]?.h2 }}
          ></h2>
        </section>
      </article>
      <p dangerouslySetInnerHTML={{ __html: commonText[locateAt]?.p }}></p>
    </article>
  );
}
