import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api.service";

export const getPromotionList = createAsyncThunk(
  "getPromotionList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.getPromotionList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendPromotion = createAsyncThunk(
  "sendPromotion",
  async (payload, thunkAPI) => {
    try {
      const response = await apiService.sendPromotion(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  promotionLoading: false,
  promotionList: [],
  sendPromotionLoading: false,
};

const dataSlice = createSlice({
  name: "promotion",
  initialState: initialData,
  reducers: {
    setSendPromotionLoading: (state, action) => {
      state.sendPromotionLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromotionList.pending, (state) => {
        state.promotionLoading = true;
      })
      .addCase(getPromotionList.fulfilled, (state, action) => {
        state.promotionLoading = false;
        state.promotionList = action.payload.data.aaData;
      })
      .addCase(getPromotionList.rejected, (state) => {
        state.promotionLoading = false;
      })

      .addCase(sendPromotion.pending, (state) => {
        state.sendPromotionLoading = true;
      })
      .addCase(sendPromotion.fulfilled, (state, action) => {
        state.sendPromotionLoading = false;
      })
      .addCase(sendPromotion.rejected, (state) => {
        state.sendPromotionLoading = false;
      });
  },
});

export const { setSendPromotionLoading } = dataSlice.actions;

export default dataSlice.reducer;
