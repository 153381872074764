import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import ChooseBodyType from "../../../components/common/choose.body.type";
import DownloadTab from "../../../components/common/download.tab";
import { Swiper, SwiperSlide } from "swiper/react";
import CommonPackage from "../../../components/common/common.package";
import { useEffect, useState } from "react";
import CommonStep from "../../../components/common/common.step";

export default function WrappingService() {
  const { wrappingList, wrappingPackageList } = useSelector(
    (state) => state.service
  );
  const { threeBodyTypeList, selectedBodyType } = useSelector(
    (state) => state.common
  );

  const [packageList, setPackageList] = useState({});

  useEffect(() => {
    if (wrappingPackageList[selectedBodyType]) {
      setPackageList(wrappingPackageList[selectedBodyType]);
    } else {
      setPackageList([]);
    }
  }, [wrappingPackageList, selectedBodyType]);

  return (
    <section
      className="w-100 flex flex-column items-center"
      style={{ background: "#131417" }}
    >
      <article className="wrapping-service">
        <section className="wrapping-text">
          <h3>Jus-Tint Wrapping Service</h3>
          <p className="--grey">
            Jus-tint vehicle wraps provide style and protection for your car at
            all times.
          </p>
          <p>
            Jus-tint high-quality wraps provide a sleek look, safeguard your
            paint from damage, and offer UV resistance for long-lasting
            durability.
          </p>
        </section>
        <ChooseBodyType bodyTypeList={threeBodyTypeList} />
        <CommonStep
          no={2}
          isSelecting={true}
          title="Select Package"
          subtitle="Available Packages Listed Below"
          style={{ marginBottom: "-2em" }}
        />
        <CommonPackage packageList={packageList} className="wrapping" />
        <Swiper
          className="wrapping-list"
          slidesPerView="auto"
          spaceBetween={20}
          grabCursor={true}
        >
          {wrappingList.map((wrapping, wrappingIndex) => (
            <SwiperSlide key={wrappingIndex} className="wrapping-item">
              <article className="wrapping-frame">
                <LazyLoadImage src={wrapping.image} alt={wrapping.label} />
              </article>
              <article>
                <h6 className="text-start">{wrapping.label}</h6>
                <p>{wrapping.description}</p>
              </article>
            </SwiperSlide>
          ))}
        </Swiper>
        <section className="grid center gap-5">
          <p className="--bottom">
            High durability and strong resistance to scratches, stains, and
            weather damage. Our premium wraps maintain a smooth, flawless finish
            while enhancing your car's style. <br /> Designed for long-lasting
            protection, they help preserve your vehicle's original paint.
          </p>
          <DownloadTab
            title="Check Out Available Color Films"
            href="/assets/files/colour.pdf"
            download="wraping_color.pdf"
          />
        </section>
      </article>
    </section>
  );
}
