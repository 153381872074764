import { Icon } from "@iconify/react";
import { DefaultJustint } from "../../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import formatNumber from "../../../helpers/format.number";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { ProductVideo } from "../../../assets/videos";
import { useNavigate } from "react-router-dom";
import { openAccessoriesModal } from "../../../redux/reducer/modalReducer";
import {
  clearAccessoriesInfo,
  getProductInfo,
} from "../../../redux/reducer/serviceReducer";
import { Skeleton } from "primereact/skeleton";

export default function CarProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { width } = useWindowDimensions();
  const { productLoading, accessoriesList } = useSelector(
    (state) => state.service
  );
  const { role } = useSelector((state) => state.common);

  const handleNavTo = (page) => {
    navigate(`/${page}`);
  };

  const handleViewProduct = (id) => {
    dispatch(clearAccessoriesInfo());
    dispatch(openAccessoriesModal());
    dispatch(
      getProductInfo({
        id: id,
      })
    );
  };

  return (
    <article className="w-100 grid center">
      <section className="car-product">
        <article className="w-100 flex justify-between items-baseline">
          <h2>Car Accessories</h2>
          {width > 1200 && (
            <article
              className="text-with-arrow pointer"
              onClick={() => handleNavTo("service/accessories")}
            >
              <p>View All</p>
              <article>
                <Icon icon="heroicons:arrow-long-right" />
              </article>
            </article>
          )}
        </article>
        <article className="car-grid">
          <section className="image-grid">
            <article className="car-frame">
              <section
                className="w-100 h-100"
                dangerouslySetInnerHTML={{
                  __html: `
                                <video
                                    playsinline
                                    autoPlay 
                                    muted
                                    loop
                                    src=${ProductVideo}
                                />
                            `,
                }}
              ></section>
              <article className="car-text">
                <section>
                  <h6>Limited-Time Offer</h6>
                  <span>Enjoy 32% Off</span>
                </section>
                <section>
                  <h6 className="--regular">Suspension & Steering Upgrades</h6>
                  <p>
                    The suspension system includes components such as shocks,
                    struts, springs, control arms, and sway bars.
                  </p>
                </section>
                <section
                  className="text-with-arrow --white mt-4 pointer"
                  onClick={() => handleNavTo("service/accessories")}
                >
                  <p>Shop Now</p>
                  <article>
                    <Icon icon="heroicons:arrow-long-right" />
                  </article>
                </section>
              </article>
            </article>
          </section>
          <section className="product-grid">
            {!productLoading
              ? accessoriesList.map((product, productIndex) => {
                  const isVideoFile =
                    product.media?.length > 0
                      ? [
                          "mp4",
                          "MP4",
                          "mov",
                          "MOV",
                          "webm",
                          "WEBM",
                          "mkv",
                          "MKV",
                        ].includes(product.media[0].url?.split(".").pop())
                      : false;

                  return (
                    <article
                      className="product-item pointer"
                      key={productIndex}
                      onClick={() => handleViewProduct(product.id)}
                    >
                      <article className="product-frame">
                        {isVideoFile ? (
                          <video
                            src={
                              product.media?.length > 0
                                ? product.media[0].url
                                : DefaultJustint
                            }
                            // controls
                          />
                        ) : (
                          <LazyLoadImage
                            src={
                              product.media?.length > 0
                                ? product.media[0].url
                                : DefaultJustint
                            }
                            alt={product.name.name}
                          />
                        )}
                      </article>
                      <article>
                        <h5
                          className={`one-line ${product.price ? "" : "mb-0"} `}
                        >
                          {product.name.name}
                        </h5>
                        <article
                          className={`flex justify-between ${
                            width < 450 ? "flex-column" : "items-end"
                          }`}
                        >
                          <p>
                            {product.price ? (
                              `RM ${formatNumber(parseFloat(product.price))}`
                            ) : (
                              <span className="--view-price">View Price</span>
                            )}{" "}
                            {product.promo_price && (
                              <span>{formatNumber(product.promo_price)}</span>
                            )}
                          </p>
                          {role === "agent" && product.price && (
                            <p className="--nrp">
                              NP: RM {formatNumber(product.original_price)}
                            </p>
                          )}
                        </article>
                      </article>
                    </article>
                  );
                })
              : Array.from({ length: 6 }, (_, productSkeletonIndex) => (
                  <Skeleton
                    className="product-item"
                    height={
                      width < 550 ? "240px" : width < 1200 ? "329px" : "334px"
                    }
                    width={width < 1200 ? "100%" : "250px"}
                    key={productSkeletonIndex}
                  />
                ))}
          </section>
        </article>
        <article>
          {width < 1200 && (
            <article
              className="text-without-arrow pointer"
              onClick={() => handleNavTo("service/accessories")}
            >
              <article className="view-all">
                <p>View All</p>
                <article>
                  <Icon icon="carbon:shopping-catalog" />
                </article>
              </article>
            </article>
          )}
        </article>
      </section>
    </article>
  );
}
