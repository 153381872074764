import PromotionModal from "./modals/promotion.modal";
import PromotionList from "./components/promotion.list";
import { useDispatch, useSelector } from "react-redux";
import { getPromotionList } from "../../redux/reducer/promotionReducer";
import { useEffect } from "react";
import ImageGallery from "../../components/common/image.gallery";

export default function Promotion() {
  const { isOpenPromotionModal, isOpenGallery } = useSelector(
    (state) => state.modal
  );

  return (
    <section className="promotion-page page-padding">
      <PromotionList />
      {isOpenPromotionModal !== null && <PromotionModal />}
      {isOpenGallery && <ImageGallery />}
    </section>
  );
}
