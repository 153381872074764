import { useEffect } from "react";
import scrollToTop from "../../helpers/scroll.to.top";
import WrappingCompare from "./components/wrapping.compare";
import WrappingService from "./components/wrapping.service";
import CommonVideo from "../../components/common/common.video";

export default function CarWrapping() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <article className="car-wrapping-page w-100 flex flex-column items-center">
      <WrappingService />
      {/* <CommonVideo locateAt="car-wrapping" /> */}
      <WrappingCompare />
    </article>
  );
}
