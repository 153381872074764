import { useSelector } from "react-redux";
import ChooseBodyType from "../../components/common/choose.body.type";
import ImageWithText from "../../components/common/image.with.text";
import WarrantyCoverage from "./components/warranty.coverage";
import PpfAlacarte from "./components/ppf.alacarte";
import PpfPackage from "./components/ppf.package";
import { useEffect } from "react";
import scrollToTop from "../../helpers/scroll.to.top";
import CommonVideo from "../../components/common/common.video";

export default function CarPpf() {
  const { threeBodyTypeList } = useSelector((state) => state.common);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <article className="car-ppf-page w-100 flex flex-column items-center">
      <ImageWithText locateAt="car-ppf" />
      <article
        className="w-100 flex flex-column items-center"
        style={{ gap: "50px" }}
      >
        <WarrantyCoverage />
        <ChooseBodyType bodyTypeList={threeBodyTypeList} />
        <PpfAlacarte />
        <PpfPackage />
        {/* <CommonVideo locateAt="car-ppf" /> */}
      </article>
    </article>
  );
}
