import { useParams } from "react-router-dom";
import WeSpecializeIn from "../../components/common/we.specialize.in";
import CarPpf from "../car_ppf";
import CarWrapping from "../car_wrapping";
import CarCoating from "../car_coating";
import ClaimWindscreen from "../claim_windscreen";
import InsuranceRenewal from "../insurance_renewal";
import CarTinted from "../car_tinted";
import Accessories from "../accessories";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoatingList,
  getPpfList,
  getProductList,
  getTintedList,
  getVideoList,
  getWrappingList,
} from "../../redux/reducer/serviceReducer";

export default function Service() {
  const dispatch = useDispatch();
  const { service } = useParams();

  const { isTokenChecked, token } = useSelector((state) => state.common);
  const {
    tintedLoading,
    ppfLoading,
    wrappingLoading,
    coatingLoading,
    productLoading,
    filterCategory,
  } = useSelector((state) => state.service);

  const subServiceList = {
    "car-tinted": {
      component: <CarTinted />,
      service: "tinted",
      api: getTintedList,
      loading: tintedLoading,
    },
    "car-ppf": {
      component: <CarPpf />,
      service: "ppf",
      api: getPpfList,
      loading: ppfLoading,
    },
    "car-wrapping": {
      component: <CarWrapping />,
      service: "wrapping",
      api: getWrappingList,
      loading: wrappingLoading,
    },
    "car-coating": {
      component: <CarCoating />,
      service: "coating",
      api: getCoatingList,
      loading: coatingLoading,
    },
    "claim-windscreen": {
      component: <ClaimWindscreen />,
    },
    accessories: {
      component: <Accessories />,
      service: "accessory",
      api: getProductList,
      loading: productLoading,
    },
    "insurance-renewal": {
      component: <InsuranceRenewal />,
    },
  };

  useEffect(() => {
    if (
      subServiceList[service]?.service &&
      subServiceList[service]?.service !== "accessory" &&
      !subServiceList[service]?.loading &&
      isTokenChecked
    ) {
      dispatch(
        subServiceList[service].api({
          length: 999,
          service: subServiceList[service].service,
          category_id: filterCategory,
        })
      );
    }
  }, [service, token, isTokenChecked, filterCategory]);

  return (
    <section
      className={`service-page page-padding ${
        service === "claim-windscreen" ||
        service === "insurance-renewal" ||
        service === "car-tinted" ||
        service === "car-wrapping" ||
        service === "car-ppf" ||
        service === "car-coating"
          ? "pb-0"
          : ""
      }`}
    >
      <WeSpecializeIn />
      {subServiceList[service].component}
    </section>
  );
}
