import { useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";

export default function Select({
  name,
  label,
  options,
  className,
  disabled,
  placeholder,
}) {
  const { errors, values, touched, setFieldValue } = useFormikContext();

  return (
    <>
      <section className={`element _select ${className ? className : ""}`}>
        {label && (
          <article>
            <label>{label}</label>
          </article>
        )}
        <article>
          <CreatableSelect
            isClearable
            isDisabled={disabled}
            options={options}
            placeholder={placeholder}
            onChange={(option) => setFieldValue(name, option.value)}
          />
        </article>
      </section>
      <section>
        {errors && touched && errors[name] && touched[name] ? (
          <div className="element _errors text-right no-padding error-message ms-0">
            {errors[name]}
          </div>
        ) : null}
      </section>
    </>
  );
}
