import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { CommonVertical } from "../../assets/videos";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../hook/use.window.dimensions";
import { openGallery } from "../../redux/reducer/modalReducer";
import { setImageGallery } from "../../redux/reducer/commonReducer";

export default function CommonVideo({ locateAt }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { videoList } = useSelector((state) => state.service);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [videoDurations, setVideoDurations] = useState({});
  const [remainingDuration, setRemainingDuration] = useState("00:00");
  const [activeIndex, setActiveIndex] = useState(Math.floor(8 / 2));

  const videoDescriptions = {
    JT: "Applying JT tinted film for enhanced UV protection and privacy.",
    JTIR: "Applying JTIR infrared rejection tint for superior heat reduction and comfort.",
  };

  const handlePlayVideo = (videoIndex) => {
    const videoElement = document.getElementById(`video-${videoIndex}`);

    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
        videoElement.muted = false;
        setPlayingIndex(videoIndex); // Add videoIndex to the array
      } else {
        videoElement.pause();
        setPlayingIndex(null);
      }
    }
  };

  const handleOpenGallery = (index = 0) => {
    dispatch(openGallery());

    const gallery = Object.keys(videoList || {}).flatMap((videoType) =>
      videoList[videoType].map((videoSrc) => ({
        type: "video",
        width: 1280,
        height: 720,
        sources: [
          {
            src: videoSrc,
            type: "video/mp4",
          },
        ],
      }))
    );

    if (gallery.length > 0) {
      // Ensure index is within bounds
      const validIndex = Math.max(0, Math.min(index, gallery.length - 1));

      const sortedGallery = [
        ...gallery.slice(validIndex),
        ...gallery.slice(0, validIndex),
      ];

      dispatch(setImageGallery(sortedGallery));
    }
  };

  useEffect(() => {
    let interval;

    if (playingIndex !== null) {
      const video = document.getElementById(`video-${playingIndex}`);

      if (video) {
        interval = setInterval(() => {
          setRemainingDuration(
            formatDuration(video.duration - video.currentTime)
          );

          if (video.ended) {
            clearInterval(interval);
            setRemainingDuration("00:00");
          }
        }, 1000);
      }
    } else {
      setRemainingDuration("00:00");
    }

    return () => clearInterval(interval);
  }, [playingIndex]);

  useEffect(() => {
    let durationIndex = 0;

    const loadVideoDurations = async () => {
      const durations = {};

      await Promise.all(
        Object.entries(videoList).flatMap(([category, videos]) =>
          videos.map((videoUrl) => {
            const index = durationIndex++;

            return new Promise((resolve) => {
              const video = document.createElement("video");
              video.src = videoUrl;
              video.preload = "metadata";

              video.onloadedmetadata = () => {
                durations[index] = formatDuration(video.duration);
                resolve();
              };

              video.onerror = () => resolve();
            });
          })
        )
      );

      setVideoDurations(durations);
    };

    if (Object.entries(videoList).length > 0) {
      loadVideoDurations();
    }
  }, [videoList]);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    if (playingIndex !== null) {
      const playingVideo = document.getElementById(`video-${playingIndex}`);
      if (playingVideo) {
        playingVideo.pause();
      }
      setPlayingIndex(null);
    }
  }, [activeIndex]);

  let globalIndex = 0;

  return (
    <section className={`common-video ${locateAt ? `--${locateAt}` : ""}`}>
      {/* <CommonText locateAt="car-tinted-top" /> */}
      <section className="video-text">
        <h3>Watch Our Work in Action</h3>
        <p className="--grey">
          Checkout how we apply high-quality tint for better heat rejection, UV
          protection, and privacy.
        </p>
      </section>
      <Swiper
        className="video-list"
        slidesPerView="auto"
        spaceBetween={
          width < 500 ? 50 : width < 950 ? 90 : width < 1350 ? 110 : 150
        }
        grabCursor={true}
        centeredSlides={true}
        // pagination={{
        //   type: "fraction",
        // }}
        // navigation={true}
        modules={[EffectCoverflow]}
        initialSlide={Math.floor(8 / 2)}
        effect="coverflow"
        coverflowEffect={{
          rotate: 8.75,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      >
        {Object.keys(videoList).map((videoType) =>
          videoList[videoType].map((videoSrc) => {
            const currentIndex = globalIndex++;

            return (
              <SwiperSlide
                key={currentIndex}
                className={`video-item ${
                  currentIndex === activeIndex ? "--active" : ""
                } ${playingIndex === currentIndex ? "--playing" : ""}`}
                onClick={() => {
                  if (currentIndex === activeIndex) {
                    handlePlayVideo(currentIndex);
                  }
                }}
              >
                <video id={`video-${currentIndex}`} src={videoSrc} />
                <section className="video-wrapper" />
                {playingIndex !== currentIndex && (
                  <p>{videoDurations[currentIndex]}</p>
                )}
                <Icon icon="octicon:play-24" className="play-icon" />
                <section className="video-detail">
                  <h5>{videoDescriptions[videoType]}</h5>
                  {currentIndex === activeIndex && (
                    <article>
                      <Icon
                        icon="lucide:fullscreen"
                        className="fullscreen-icon"
                        onClick={(e) => {
                          e.stopPropagation();

                          setPlayingIndex(null);
                          handleOpenGallery(currentIndex);
                        }}
                      />
                    </article>
                  )}
                </section>
              </SwiperSlide>
            );
          })
        )}
      </Swiper>
    </section>
  );
}
