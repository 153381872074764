import { useEffect } from "react";
import ImageWithForm from "../../components/common/image.with.form";
import scrollToTop from "../../helpers/scroll.to.top";
import DocumentRequired from "./components/document.required";
import WhatIsWindscreen from "./components/what.is.windscreen";
import InsurancePanel from "./components/insurance.panel";
import CommonVideo from "../../components/common/common.video";

export default function ClaimWindscreen() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <article
      className="claim-windscreen-page w-100 flex flex-column items-center"
      style={{ gap: "50px" }}
    >
      <ImageWithForm locateAt="claim-windscreen" />
      <DocumentRequired />
      <WhatIsWindscreen />
      <InsurancePanel />
      {/* <CommonVideo locateAt="claim-windscreen" /> */}
    </article>
  );
}
