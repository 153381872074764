import { configureStore } from "@reduxjs/toolkit";

//reducer
import commonReducer from "./reducer/commonReducer";
import modalReducer from "./reducer/modalReducer";
import homeReducer from "./reducer/homeReducer";
import serviceReducer from "./reducer/serviceReducer";
import cartReducer from "./reducer/cartOrderReducer";
import promotionReducer from "./reducer/promotionReducer";
import profileCommissionReducer from "./reducer/profileCommissionReducer";

const reducer = {
  common: commonReducer,
  modal: modalReducer,
  home: homeReducer,
  service: serviceReducer,
  cart_order: cartReducer,
  promotion: promotionReducer,
  profile_commission: profileCommissionReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: process.env.REACT_APP_DEV_TOOL,
});

export default store;
